@import './lbd/variables';
.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .flex-column-container {
    display: flex;
    flex-direction: column;
  }
}
.wrap {
  flex-wrap: wrap;
}
.centered-row {
  @extend .flex-row;
  align-items: center;
}

.stretch {
  flex: 1;
  justify-content: space-between;
}

.baseline {
  align-items: baseline;
}

.center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.right {
  margin-left: auto;
}

.space-left {
  margin-left: 16px;
}

.space-above {
  margin-top: 16px;
}

.space-above-8 {
  margin-top: 8px;
}

.space-above-16 {
  margin-top: 16px;
}

.space-bottom {
  margin-bottom: 16px;
}

.space-bottom-8 {
  margin-bottom: 8px;
}

.capitalize {
  text-transform: capitalize;
}

.component-coming-soon.hidden {
  visibility: collapse;
}
@media only screen and (max-width: 992px) {
  .table-item:first-child {
    margin-top: 0px;
  }
}
.table-item {
  background-color: white;
  border-radius: 4px;
  border: 0.5px solid #ebebeb;
  padding: 24px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
  }
}

.coming-soon {
  display: flex;
  justify-content: center;
  min-height: 75vh;
  img {
    width: 512px;
    @media only screen and (max-width: 992px) {
      width: 320px;
    }
  }
}

.component-coming-soon {
  opacity: 0.33 !important;
  * {
    pointer-events: none;
  }
}

@keyframes slideLeftAndRight {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-20px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  animation-duration: 1.5s;
  animation-name: flickerAnimation;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
}

.slow-shake {
  animation-duration: 1.5s;
  animation-name: slideLeftAndRight;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: ease-in-out;
}

.popover {
  z-index: 1393 !important;
}
.uppercase {
  text-transform: uppercase;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-5 {
  margin-top: 5px;
}
.mt-25 {
  margin-top: 25px;
}
.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}

.wedge-sup {
  color: $wedge-primary-green;
  padding-left: 4px;
}
.noPadding {
  padding: 0;
}
.pointer {
  cursor: pointer;
}

.ldap-status-link {
  cursor: pointer;
  text-decoration: underline;
  border: none !important;
  outline: none !important;
  padding: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
