.input-text-editor {
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  color: #5f5f5f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 0.67857143em 1em;
  min-height: 160px;
  height: 200px;
  resize: vertical;
  overflow-y: auto;
  outline: none;

  .rdw-editor-main {
    height: calc(100% - 40px);
  }

  .DraftEditor-root {
    min-height: 100px;
    height: inherit;
  }

  &:focus {
    border-color: #85b7d9;
  }
}
