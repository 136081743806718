@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/lbd/mixins.scss';

.stepper-container {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  width: 50%;
  overflow: hidden;
  align-items: flex-end;

  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }

  .single-step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 576px) {
      flex: 1;
    }

    .round-step-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      &:before {
        position: absolute;
        content: '';
        border: 10px solid $wedge-white-two;
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      &:after {
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        top: 25px;
        left: 100%;
        width: 9999px;
        z-index: -1;
      }
      &.active:not(.last)::after {
        border: 1px dashed $wedge-primary-orange;
      }
      &.done:after,
      &.pending:after {
        background-color: $wedge-secondary-gray;
      }
      &.last:after {
        background-color: $wedge-white-two;
        height: 2px;
      }

      .round-step {
        border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        font-size: $font-size-large;
        font-weight: $font-weight-bold;
        z-index: 2;

        &.active {
          border-color: $wedge-primary-orange;
          background-color: $wedge-primary-orange;
          color: $wedge-white-two;
        }

        &.done {
          border-color: $wedge-primary-blue;
          background-color: $wedge-primary-blue;
          color: $wedge-white-two;
        }

        &.pending {
          border-color: lightgray;
          background-color: lightgray;
          color: $wedge-white-two;
        }
      }
    }
  }
}

.step-title {
  text-align: center;
  font-size: $font-size-navbar;
  color: $wedge-secondary-black;
  font-weight: $font-weight-bold;
  margin: $margin-bottom;
}
