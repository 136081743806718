@import '../../../assets/sass/lbd/variables.scss';
@import '../../../assets/sass/lbd/mixins.scss';
@import '../../../assets/sass/common.scss';
@import '../../../assets/sass/lbd/mixins/_vendor-prefixes.scss';

.eula-wrapper.wrapper {
  background-color: $wedge-white-two;
  height: auto;

  .d-flex {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  .eula {
    display: flex;
    justify-content: center;

    &--content {
      display: flex;
      position: relative;
      z-index: 4;
      justify-content: center;
      margin-top: 6vh;

      .title {
        color: $wedge-primary-black;
        font-size: calc(1vw + 1vh + 1vmin);
        font-weight: 600;
      }

      .eula--container {
        width: 58%;

        @include respond-to($tablet-portrait) {
          width: 70%;
        }

        @include respond-to($phone) {
          width: 95%;
        }

        p {
          margin-bottom: 10px;
          font-size: 14px;
        }

        .scrollingContent {
          height: 62vh;
          max-height: 70vh;
          overflow-y: scroll;
          background: #fff;
          padding: 5%;
          margin-top: 2vh;
          border: 1px solid #d0d4d6;

          p {
            margin-bottom: 10px;
            font-size: 14px;
          }

          .header {
            margin-top: 25px;
            font-size: 18px;
            margin-bottom: 5px;
            & b {
              text-transform: uppercase;
            }
          }

          .sub-header {
            margin-top: 0px;
            font-size: 16px;
            margin-bottom: 3px;
          }

          & b {
            font-weight: 600;
          }
        }

        .buttonDiv {
          position: relative;
          margin-top: 15px;

          p {
            position: absolute;
            right: 0px;
            width: 140px;
          }
        }

        .versionId {
          display: flex;
          justify-content: space-between;
          display: -webkit-flex;
          display: flex;
          border-bottom: 1px solid #d0d5d8;
          border-bottom-color: rgb(208, 213, 216);
          padding: 15px 0px 15px;
          font-size: 12px;
          color: #76888f;
        }

        .input-container {
          margin-bottom: 18px;
          padding: 16px;
          background-color: white;
          border: 1px solid #d0d4d6;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        input {
          flex: 1;
          font-size: 18px;
          color: $wedge-blue-gray;
          border: 0;
          margin-left: 16px;

          &::placeholder {
            color: #c5c8c9;
          }
        }

        .icon-container {
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          .input-icon {
            max-height: 18px;
            max-width: 18px;
          }
        }

        .login-button {
          cursor: pointer;
          color: white;
          padding: 6px;
          background-color: #4986c5;
          border-radius: 3.8px;
          font-size: 18px;
          text-align: center;
          margin: 0;
          width: 100%;
        }
      }
    }

    &--footer {
      z-index: 1;
      position: fixed;
      bottom: -125px;

      .footer--image {
        background-color: #f9f9f9;
        width: 100%;
        object-fit: contain;

        @media only screen and (max-width: 992px) {
          width: unset;
          max-height: 50vh;
        }
      }
    }
  }
}
