@import '../assets/sass/common.scss';
@import '../assets/sass/lbd/variables.scss';
@import '../assets/sass/lbd/mixins/vendor-prefixes';
@import '../assets/sass/lbd/mixins';

.modal {
  &__content {
    padding: 16px;

    .name-container {
      width: 65%;
      min-width: 200px;
    }
    .description-container {
      max-width: 61%;
      .field__textfield {
        word-wrap: break-word;
      }
    }

    &.padded {
      padding: 24px 32px;
      .object-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 96%;
      }
    }

    @media only screen and (max-width: 992px) {
      padding: 8px;

      &.padded {
        padding: 16px;
      }
    }

    .card .content {
      padding: 32px;
      @media only screen and (max-width: 992px) {
        padding: 16px;
      }
    }

    .form-row {
      @extend .flex-row;
      justify-content: space-between;
      align-items: flex-start;

      @media only screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      }
    }

    .form__group {
      &.full {
        max-width: 100% !important;
      }
      @media only screen and (min-width: 992px) {
        flex: 1 1 100%;
        max-width: 250px;
      }
      @media only screen and (max-width: 992px) {
        max-width: 992px;
        margin-bottom: 8px;
      }
    }

    p {
      color: $wedge-blue-gray;

      &.big {
        font-size: 24px;
        font-weight: normal;
        margin-bottom: 0;
      }
      &.error {
        background-color: rgba(red, 0.1);
        border-radius: 4px;
        color: rgba(red, 0.75);
        font-size: 14px;
        margin-bottom: 8px;
        padding: 8px 12px;
      }
      &.error-text {
        font-size: 10px;
        color: red;
      }
      &.medium {
        font-size: 12px;
      }
    }

    .option-container {
      @extend .flex-row;
      align-items: center;
      cursor: pointer;
      p {
        margin-bottom: 0;
      }
      .option {
        flex: 1;
        font-size: 20px;
        font-weight: normal;
        color: $wedge-blue-gray;
      }

      .type-icon--container {
        width: 48px;
      }

      .type-icon {
        max-width: 30px;
        max-height: 24px;
        margin-right: 24px;
      }

      .icon {
        max-height: 32px;
        color: #7f97a0;
      }

      .go-arrow {
        height: 16px;
      }
    }
    .option-container:last-of-type {
      margin-bottom: 8px;
    }
    .divider {
      background-color: #bfbfbf;
      opacity: 0.2;

      &-horizontal {
        margin-top: 24px;
        margin-bottom: 24px;
        height: 1px;
        width: 100%;
      }
    }

    .toggle-selected {
      color: #fff;
      background-color: #36cb98;
      border-color: darken(#36cb98, 5%);
    }

    &.privacy-policy {
      width: 95%;
      max-width: 95%;
      margin: 0 auto;
      padding: 15px 2%;
      color: $wedge-primary-black;
      font-size: 14px;
      a {
        text-decoration: none;
        color: #4a87c6;
        font-style: bold;
      }
      h2,
      h3,
      h4,
      h5,
      p {
        font-size: 14px;
        color: $wedge-primary-black;
      }
      h6 {
        margin-bottom: 5px;
        text-transform: none;
      }
      .italic {
        font-style: italic;
      }
      .encloser {
        height: 68vh;
        padding: 3% 3%;
        border-radius: 10px;
        border: 1px solid #d0d4d6;
        background: $wedge-white;
      }

      .main {
        &.header {
          margin-bottom: 10px;
          margin-top: 0px;
          h4 {
            font-size: 20px;
            font-weight: 600;
            margin-top: 5px;
            margin-bottom: 10px;
          }
        }

        &.content {
          height: 63.5vh;
          max-height: 63.5vh;
          width: auto;
          padding-right: 20px;
          overflow-y: auto;
          overflow-x: hidden;
          background: $wedge-white;
          .basic {
            margin-bottom: 30px;
          }

          &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #f5f5f5;
          }
          &::-webkit-scrollbar {
            width: 5px;
            border-radius: 10px;
            background-color: #f5f5f5;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #6d8994;
          }
        }
      }
      .content {
        .black {
          color: $wedge-primary-black;
        }
        .head {
          font-weight: 600;
        }
        .section {
          padding-left: 25px;
          margin-bottom: 20px;
          position: relative;
          &::before {
            content: '•';
            position: absolute;
            left: 0px;
            top: -10px;
            font-size: 35px;
            color: #4a87c6;
          }

          .header {
            margin-top: 5px;
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: 600;
          }
          .content {
            margin-top: 15px;
          }
        }
        .menu {
          margin-bottom: 15px;
          .header {
            margin-top: 5px;
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 18px;
          }
          .content {
            margin-top: 5px;
          }
        }
        .topic {
          margin-bottom: 10px;
          .header {
            margin-top: 15px;
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 16px;
          }
          .content {
            ul {
              &.dashed {
                list-style: none;
                padding-left: 0;
                margin-top: 10px;
                li {
                  position: relative;
                  padding-left: 20px;
                  &:before {
                    content: '-';
                    position: absolute;
                    color: #4a87c6;
                    font-size: 30px;
                    left: 0px;
                    top: -14px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.new-address-translation-survey {
      .form__group {
        flex: 1 0 100%;
      }

      .full {
        justify-content: flex-start;

        &:last-child {
          width: 100%;
        }
      }
      .card .content {
        padding: 32px;
        @media only screen and (max-width: 992px) {
          padding: 12px;
        }
      }
      .icon-container {
        @media only screen and (max-width: 992px) {
          align-self: center;
        }
        .middleIcon {
          @media only screen and (max-width: 992px) {
            justify-content: center;
            display: flex;
          }

          .dividerImage {
            width: 45px;
            height: 45px;
            margin-top: 5px;
          }
        }
      }
      .checkbox-label {
        margin-top: 16px;
        margin-bottom: 24px;

        span.title {
          position: absolute;
          margin-left: 4px;
          font-size: 14px;
          font-weight: 600;
          color: $wedge-blue-gray;
          text-transform: none;
          margin-top: 2px;
        }
      }

      .disabled {
        &.wedge-checkbox-container {
          opacity: 0.4;
          cursor: not-allowed;
          &:hover input ~ .checkmark {
            background-color: #eee;
          }
        }
      }

      .notes-container {
        flex: 2;
      }

      .expiry-container {
        flex: 1;
      }

      .actions-container {
        flex: 1;
      }

      .add-button {
        margin-right: 8px;
      }
      .box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .section {
          margin-top: 20px;
          margin-bottom: 20px;
          flex: 1 1;
          justify-content: center;
          display: flex;
          flex-direction: column;
          text-align: center;
          align-self: flex-start;
          @media only screen and (max-width: 992px) {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 0;
          }
          .mainHeader {
            color: #36cb98;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
          }
          input {
            width: 100%;
            &::placeholder {
              color: #d3d7d8;
            }
          }
        }
        .middleIcon {
          flex: 0.5 1;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media only screen and (max-width: 992px) {
            display: none;
          }
          p {
            margin-bottom: 10px;
          }
          & .dividerImage {
            align-self: center;
            width: 60px;
            height: 60px;
          }
        }
      }

      .ecosystem--users_single,
      .policy--application_single,
      .policy--destination_single,
      .policy--sources_single,
      .policy--services_single {
        @extend .flex-row;
        margin: 8px 0 0 0;
        justify-content: space-between;
        p {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .action-index--image {
        width: 14px;
        margin-right: 5px;
      }
      .form-row {
        &.boxContainer {
          flex-direction: column;
          justify-content: center;
          display: flex;
          align-items: center;
          .sectionContainer {
            width: 100%;
            p.subHeader {
              margin-bottom: 15px;
              height: 40px;
            }
          }
          .form__group {
            max-width: 100%;
            margin-top: 10px;
          }
          .sources-container,
          .destinations-container,
          .destinationPort-container,
          .services-container {
            .form__label {
              text-align: left;
              margin-left: 12px;
              margin-bottom: -15px;
            }
            &.form__group {
              margin-top: 20px;
            }
            .form__textinput {
              padding-left: 12px;
              margin-bottom: 0;
              margin-top: 15px;
              height: 40px;
            }
          }
        }
      }

      .form__textinput {
        font-size: 14px;
        width: 100%;
        margin-top: 8px;
        margin-bottom: 16px;
        background-color: transparent;
        border: 0;
        border-bottom: 1px #ededed solid;
        padding: 4px 8px 4px 0;
        @include transition(0.3s, $transition-ease);

        @media only screen and (max-width: 992px) {
          margin-bottom: 8px;
        }

        &::placeholder {
          color: #d3d7d8;
          font-size: 14px;
          font-weight: normal;
        }

        &.multiline {
          word-break: break-word;
        }

        &:focus {
          border-bottom: 2px $wedge-primary-orange solid;
          outline: 0;
        }
      }
    }

    &.create-new-ecosystem {
      @media screen and (max-width: 1275px) {
        .flex-column-container {
          display: flex;
          flex-direction: column;
          .flex-column {
            flex: 1;
          }
        }
      }
      @media only screen and (min-width: 992px) {
        .form__group {
          max-width: 75%;
        }
        .ecosystem--user__new,
        .ecosystem--users_single {
          .form__textinput {
            min-width: 252px;
          }
        }
      }
      .ecosystem--users_single {
        @extend .flex-row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .newUser__label {
          color: red;
          position: absolute;
          top: -2px;
          font-size: 12px;
        }
        .form__textinput {
          width: 50%;
        }
        .user__divider {
          padding: 0 12px;
        }
        i {
          color: $wedge-light-gray;
          font-size: 20px;
          margin-left: 16px;
          margin-right: 4px;
          cursor: pointer;
          &:hover {
            color: $wedge-primary-gray;
          }
        }
      }
      .ecosystem--user__new {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: 8px;

        .form__textinput {
          margin-bottom: 0;
          margin-right: 12px;
        }
      }
      .wedge-checkbox-container {
        margin-left: 32px;
        .checkmark {
          height: 18px;
          width: 18px;
          &:after {
            top: 3px;
            left: 6px;
          }
        }
      }
      .nsp-container {
        display: flex;
      }
    }

    &.new-gateway-survey {
      .flex-two {
        flex: 2;
      }
      .flex-three {
        flex: 3;
      }
      .unset-maxWidth {
        max-width: unset;
      }
      .ip-form-divider {
        margin-left: 16px;
        margin-right: 16px;
      }
      .card.basic .content .form-row {
        .form__group:nth-child(2) {
          @media (min-width: 1368px) {
            max-width: 40%;
          }
        }
      }
      .content .divider {
        color: #d0d4d6;
        height: 1px;
        opacity: 1;
      }
      .gateway-type {
        flex: 1;
        @media (min-width: 1368px) {
          max-width: 40%;
        }
        @media (max-width: 1367px) {
          max-width: 250px;
        }
      }
      .content .gateway-config {
        display: flex;
        &__ips {
          @media (min-width: 992px) {
            width: 250px;
          }
          .wireguard-pub-key {
            margin-bottom: 12px;
          }
        }
        &__networks {
          @media (min-width: 1368px) {
            width: 40%;
          }
          .wg-public {
            display: flex;
            input:first-child {
              flex: 2;
            }
            input:last-child {
              flex: 1;
              margin-left: 12px;
            }
          }
        }
      }
      label {
        text-transform: none;
        &.checkbox-label {
          margin-top: 8px;
        }
        .check_title {
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
        }
        .check_subtitle {
          font-size: 12px;
          line-height: 2;
        }
      }

      .local-networks--container {
        margin-top: 8px;
        background-color: #fdfdfd;
        border: 1px #ececec solid;
        border-radius: 2px;
        padding: 8px 8px 0 8px;

        table {
          width: 100%;
        }

        tr {
          border-bottom: 1px #ececec solid;
          height: 50px;
          vertical-align: center;

          &.new-entry {
            border-bottom: 0;
            vertical-align: center;
            td:first-child {
              padding-right: 12px;
            }
          }
        }

        th,
        td {
          font-size: 14px;
          font-weight: bold;
          color: #869da7;
          &.last {
            width: fit-content;
            padding: 4px 8px;
          }
        }
      }

      .gateway {
        &__detail {
          display: flex;
          &.form-row {
            @media only screen and (max-width: 1550px) {
              flex-direction: column;
              justify-content: center;
            }
          }
          .break-all {
            word-break: break-all;
          }
          .commit-msg {
            color: $wedge-blue-gray;
            display: flex;
            flex-wrap: wrap;
            .link {
              color: $wedge-primary-blue;
              text-decoration: underline;
              cursor: pointer;
              &:hover {
                color: $wedge-primary-green;
              }
            }
          }
          .flex-one {
            flex: 1 1;
          }
          &-info {
            word-break: break-all;
          }
          &-vpn {
            @include respond-to($large-desktop) {
              height: fit-content;
            }
            .vpn-collapse {
              margin-left: 24px;
              .field__group {
                margin-bottom: 12px;
              }
            }
            .vpn_title {
              color: $wedge-primary-blue;
              cursor: pointer;
              display: flex;
              align-items: center;
              font-size: 16px;
              line-height: 32px;
              width: fit-content;
            }
            .psk-info {
              i {
                cursor: pointer;
              }
              p,
              &__options {
                color: $wedge-light-gray;
                font-size: 14px;
                margin-top: 12px;
              }
              &__options {
                display: flex;
                align-items: center;
                @include respond-to($phone) {
                  display: block;
                }
                .psk-data {
                  display: flex;
                  align-items: center;
                  .psk-actions {
                    display: flex;
                    align-items: center;
                  }
                  @media only screen and (max-width: 340px) {
                    display: block;
                    .psk-actions {
                      margin-top: 5px;
                    }
                  }
                }
                .psk-option {
                  display: flex;
                  align-items: center;
                  &::before {
                    content: '|';
                    padding-left: 8px;
                    padding-right: 8px;
                  }
                }
                .psk-text {
                  font-size: 11px;
                  background: none;
                  border: 0.5px solid $wedge-light-gray;
                  border-radius: 3px;
                  padding: 6px;
                  margin-left: 6px;
                  width: 160px;
                  @include respond-to($phone) {
                    color: $wedge-primary-black;
                  }
                }
                .psk-show {
                  display: flex;
                  height: 20px;
                }
              }
            }
          }
          &-internet {
            display: flex;
            @media only screen and (max-width: 992px) {
              flex-direction: column;
            }
          }
          .local-networks--container {
            width: 60%;
          }
        }
      }
    }
    .form-section {
      margin-top: 15px;
    }
    .form-header {
      padding: 0;
      margin: 0 0 20px 0;
      font-size: 18px;
      color: #6d8994;
    }
    .popOverContainer {
      display: flex;
      align-items: center;
      justify-content: unset;
      h5 {
        font-size: 14px;
        font-weight: 600;
        color: #6d8994;
        margin-bottom: 8px;
      }
      @media screen and (max-width: 992) {
        justify-content: center;
      }
      &.checkbox-help {
        .help-icon {
          margin-bottom: 5px;
        }
      }
    }

    &.new-user-survey {
      padding: 0;
      h5 {
        color: #6d8994;
      }
      h3 {
        color: #6d8994;
        font-weight: 500;
      }
      .small-icon {
        margin-right: 5px;
      }
    }
    &.new-user-survey-manual {
      .select-with-create {
        padding: 4px 0 0px 0;
      }
    }
    &.new-user-survey-csv {
      color: #6d8994;
      .custom-header {
        font-size: 14px;
        font-weight: bold;
      }
      label {
        margin-top: 0px;
        text-transform: none;
      }
      .headerCheckbox {
        margin-top: 10px;
      }
    }

    &.user-invitation,
    &.new-user-survey-manual,
    &.new-user-survey-csv {
      .local-users--container {
        overflow: scroll;
        table {
          width: 100%;
          min-width: 625px;
        }
        tbody {
          display: flex;
          flex-direction: column;
        }

        tr {
          display: flex;
          vertical-align: middle;
          margin-top: 8px;
          padding: 5px 0;

          &.tHeader {
            margin-top: 0px;
            border-bottom: 1px #ececec solid;
            margin-bottom: 8px;
            font-weight: bold;
          }
          &.new-entry {
            margin-top: 8px;
            border-bottom: 0;
            vertical-align: middle;
          }
          &.entries {
            display: flex;
            align-items: center;
            td {
              font-size: 16px;
            }
            .fName {
              flex: 1;
            }
            .lName {
              flex: 1;
            }
            .role {
              flex: 1.5;
            }
            .email {
              flex: 2;
            }
            .pwd {
              flex: 1;
            }
            .last {
              flex: 0.25;
              display: flex;
              justify-content: center;
            }
          }
        }

        th,
        td {
          font-size: 14px;
          color: #869da7;
          padding-right: 12px;
          overflow-wrap: anywhere;
          &.last {
            width: fit-content;
            padding: 4px 8px;
          }
        }
      }
    }
    &.user-invitation {
      .link {
        text-decoration: underline;
        cursor: pointer;
        color: #4986c5;
        font-size: 16px;
      }
      .local-users--container {
        margin-top: 10px;
        table thead {
          padding: 0 12px;
          display: block;
          border: 1px solid #6d8994;
          background-color: #e7e7e7;
        }
        table tbody {
          padding: 0 12px;
          max-height: 200px;
          overflow-y: auto;
          display: block;
          border: 1px solid #6d8994;
          border-top: 0;
        }
        tr {
          display: flex;
          vertical-align: middle;
          margin-top: 0;

          &.tHeader {
            margin-top: 0px;
            border-bottom: 1px #ececec solid;
            margin-bottom: 0;
            font-weight: bold;
          }
        }
      }
    }
    &.confirmation {
      .blue.underline {
        color: $wedge-primary-blue;
        text-decoration: underline;
      }
      .details {
        font-weight: 600;
      }
      .card .content {
        padding: 24px;
        @media screen and (max-width: 991px) {
          padding: 16px;
        }
        h3 {
          margin-top: 0;
          color: #6d8994;
          font-weight: 500;
        }
      }
    }

    &.user-invitation,
    &.log-settings,
    &.ldap-config {
      color: #6d8994;
      padding: 0px;
      margin-bottom: 15px;
      .form__group {
        @media only screen and (min-width: 992px) {
          max-width: unset;
        }
      }

      .hidden {
        display: none;
      }

      .card .content {
        padding-top: 24px;
        padding-bottom: 24px;
        @media screen and (max-width: 991px) {
          padding-top: 16px;
        }
        margin-top: 0px;
        h3 {
          margin-top: 0;
        }
      }
      label {
        text-transform: none;
      }
      .sub-header {
        color: #6d8994;
        margin-top: 20px;
        .first {
          margin-top: 15px;
        }
      }
      .header-block {
        margin-bottom: 5px;
        margin-top: 10px;
        h5 {
          margin-bottom: 8px;
          margin-top: 0;
          font-size: 14px;
          font-weight: 600;
        }
      }
      p {
        &.cert-trigger {
          display: flex;
          align-items: center;
          font-size: 13px;
          margin-top: 5px;

          .cert-Header {
            font-weight: 400;
          }
          cursor: pointer;
          .certLink {
            display: flex;
            color: $wedge-primary-blue;
            margin-left: 4px;
            font-style: underline;
            text-decoration: underline;
            &:hover {
              color: $wedge-primary-green;
            }
          }
        }
      }
      .idpOptions,
      .form__radioGroup {
        margin-left: -5px;
        .contextHelp {
          margin-left: 0px;
        }
      }
      .selected-option {
        margin-right: 5px;
        font-size: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .credentials {
        margin-top: 35px;
      }
      .summary {
        .download__options {
          display: flex;
          align-items: center;
        }
        .desc-header {
          margin-top: 15px;
        }
        i {
          margin: 0 4px;
          cursor: pointer;
        }
      }
      .ldap-config-description,
      .description {
        color: #6d8994;
        .details {
          font-weight: 600;
        }
      }
      .ldap-config-connection,
      .connection {
        .ldap-url-config,
        .url-config {
          .header-block {
            h5 {
              margin-bottom: 0px;
              margin-top: 10px;
            }
          }
          .ldap-url,
          .url {
            justify-content: unset;
            .scheme {
              max-width: 100px;
            }
            .url {
              .url-text {
                height: 39px;
                @media screen and (max-width: 992px) {
                  height: auto;
                }
              }
            }
          }
          .startTLS {
            margin: 0;
          }
          .privCert {
            display: flex;
            align-items: center;
          }
          .content-option {
            margin-bottom: 10px;
          }
        }
      }
      .ldap-config-ssl,
      .ssl {
        .form__group {
          max-width: unset;
        }
        .privCert {
          display: flex;
        }
        .content-option {
          margin-bottom: 10px;
        }
      }

      .__react_component_tooltip.show {
        opacity: 0.9;
        margin-top: -12px;
        margin-left: -12px;
        visibility: visible;
      }
      .btn-wrapper {
        width: 100%;
        .btn {
          border-top: 1px solid #e6ebf1;
          box-shadow: 0 2px 0 0 #e6ebf1;
          &:focus {
            outline: none;
          }
        }
        .ml5 {
          margin-left: 5px;
        }
        .btn-container {
          display: flex;
          @media only screen and (max-width: 992px) {
            width: 100%;
            justify-content: space-between;
          }
          .btn-group {
            &.dropdown {
              display: flex;
              margin-left: 5px;
            }
            .dropdown-menu {
              width: 100%;
              border-radius: 0;
            }
          }
        }
      }
    }
    &.new-thing-survey {
      .type {
        margin-top: 8px;
        margin-bottom: 16px;
      }
      .thing__details {
        display: flex;
        @media only screen and (max-width: 992px) {
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
        }

        .thing__name-type {
          flex: 1;
        }
        .thing__options {
          flex: 1;
          .field__group {
            margin-bottom: 36px;
          }
          .thing__contribute span {
            color: $wedge-primary-blue;
          }
        }
      }
    }

    &.new-address-survey {
      .type--thing_single {
        @extend .flex-row;
        margin: 8px 0;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        justify-content: space-between;
      }

      .objectinfo {
        @extend .flex-column;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        @media only screen and (max-width: 992px) {
          align-items: flex-end;
        }

        &__button {
          display: inline-block;
          align-items: center;
          background-color: #f8fdff;
          color: $wedge-blue-gray;
          border: 0.5px #c5d4e2 solid;
          border-radius: 4px;
          padding: 8px 12px;
          text-align: center;
          cursor: pointer;
          box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.1);

          & .small-icon {
            height: 20px;
            width: 20px;
            margin: 0 0 0 8px;
          }

          img {
            margin-left: 8px;
          }

          span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
          }

          &:hover {
            box-shadow: 0px 5px 10px -2px rgba(136, 136, 136, 0.2);
          }
        }

        &__title {
          color: $wedge-blue-gray;
          white-space: nowrap;
          margin-top: 16px;
          margin-bottom: 18px;
          max-width: 192px;
          text-align: center;
          text-overflow: ellipsis;
          overflow-x: hidden;
          height: 16px;
          font-size: 16px;
        }

        &__type-container {
          @extend .flex-row;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;

          img {
            margin-right: 8px;
          }
        }
      }
      .nsp-container {
        display: flex;
      }
    }
    &.new-nats-survey {
      min-width: 1200px;
      .full {
        justify-content: flex-start;

        &:last-child {
          width: 100%;
        }
      }
    }

    .dropdown.open.btn-group .dropdown-toggle {
      background-color: transparent;
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.newUserAlert,
.action__confirm,
.commit-result {
  padding: 32px 24px;
  font-size: 18px;
}

.newUserAlert {
  p:first-child {
    padding-bottom: 12px;
  }
  li {
    font-size: 14px;
  }
}

.commit-result {
  .result-header {
    color: $wedge-blue-gray;
    font-size: 24px;
    padding-bottom: 24px;
  }
  .result-body {
    word-break: break-all;
    & p {
      line-height: 32px;
    }
  }
}

.action__confirm {
  p.title {
    word-break: break-word;
  }
  .form__textinput {
    margin-bottom: 0;
  }
}

.commit-finish .wedge-modal__footer {
  text-align: right;

  button {
    width: 72px;
  }
}

.survey__footer {
  display: flex;
  justify-content: space-between;

  .new-policy & {
    position: fixed;
    right: 60px;
    bottom: 60px;
  }
  .btn {
    min-width: 100px;
  }
}

.nat__footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .new-policy & {
    position: fixed;
    right: 60px;
    bottom: 60px;
  }
  .rightActions {
    button:not(:last-child) {
      margin-right: 10px;
    }
  }
  .btn {
    min-width: 100px;
  }
}

.active-text {
  color: $blue-color-bottom;
}

.expiry-input {
  border: none;
  border-bottom: 1px solid #ededed;
  margin-top: 16px;
  padding: 4px 0;
  width: 100%;
}

.flex-3 {
  flex: 3;
}
.flex-2 {
  flex: 2;
}

#object__tabs {
  .nav.nav-tabs {
    border: none;
    padding: 16px 40px 0px;

    @media only screen and (max-width: 992px) {
      padding: 16px 16px 0 16px;
      display: flex;
    }
  }
  .object__tab {
    border: none;
    font-size: 16px;
    @media only screen and (max-width: 992px) {
      flex: 1;
      text-align: center;
    }
    &.lessPadded {
      a {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    a {
      border: none;
      border-bottom: 2px solid transparent;
      color: $wedge-light-gray;
      font-weight: 600;
      &:hover,
      &:focus {
        background-color: #f7f7f7 !important;
        border-bottom: 2px solid $wedge-secondary-gray;
        outline: none;
      }
    }

    &.active {
      border: none;
      border-bottom: 2px solid $wedge-primary-blue;
      a {
        background-color: #f7f7f7 !important;
        border: none;
        color: $wedge-blue-gray;
      }
    }
  }
}
