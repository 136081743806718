html {
  font-size: 16px;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.ecosystems-uuid-error {
  background-color: #ff7272;
  color: white;
  padding: 1em;
}

.hide-component {
  display: none !important;
}

body {
  iframe:last-of-type {
    display: none;
  }
}

legend {
  width: unset;
}

.notistack-Snackbar .notistack-MuiContent {
  &.notistack-MuiContent {
    flex-wrap: nowrap;
    #notistack-snackbar {
      width: 92%;
      word-break: break-word;
    }
  }
  &.notistack-MuiContent-success {
    border: 1px solid #29cc97;
    background: #cce6cc;
    color: #049f0c;

    .MuiIconButton-root .MuiSvgIcon-root {
      color: #049f0c;
    }
  }
  #notistack-snackbar {
    font-size: 14px;
  }
}
