@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/common.scss';
@import '../../assets/sass/lbd/mixins/_vendor-prefixes.scss';

.ecosystems__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $logo-container-height;
  @media (max-width: 767px) {
    height: $logo-container-height_small_screen;
  }
  .navbar-item {
    .dropdown {
      .dropdown-toggle {
        background-color: #f9f9f9 !important;
      }
    }
  }
  .logo {
    height: 100%;
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    .logo-img {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__normal {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 850px) {
      padding-left: 0;
      .bigLogo {
        display: none;
      }
      .smallLogo {
        height: 24px;
        width: auto;
      }
    }
    @media (min-width: 850px) {
      .bigLogo {
        height: 48px;
        width: auto;
        max-width: 120px;
      }
      .smallLogo {
        display: none;
      }
    }
  }

  .main-panel {
    width: 85%;
    .navbar-brand {
      height: 98.95%;
      padding: 5px 20px;
      margin-left: 0;
      @extend .centered-row;
      @include transition(0.2s, null);
      color: $wedge-blue-gray;
      font-size: 22px;
      cursor: pointer;
      border-bottom: 3px solid transparent;

      &.active,
      &:hover {
        border-bottom: 3px solid $wedge-primary-blue;
      }
    }
    .navbar-user {
      height: 28px;
      width: 28px;
      margin-right: 5px;
    }
    .nav-profile {
      align-items: center;
      p {
        font-size: 14px;
        color: $wedge-blue-gray;
        margin-bottom: 0;
      }
    }

    .navbar-default {
      align-items: center;
      height: 100%;
      background-color: $wedge-white-two;
      border-bottom: 1px solid rgba(#bfbfbf, 0.4);
      .container-fluid {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 0px;
        .navbar-header {
          height: 100%;
          padding-bottom: 3px;
          .navbar-brand {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .navbar-collapse.collapse {
          display: flex !important;
          align-items: center;
          margin-left: auto;
          @media (max-width: 769px) {
            ul {
              &.nav.navbar-nav.navbar-right {
                li {
                  &.navbar-item.last {
                    .navbar-user {
                      margin-right: 0;
                    }
                    a {
                      padding: 4px;
                      .nav-profile .btn-group button {
                        padding: 4px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
