.status-filter {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  .filter-option {
    border-radius: 8px;
    color: '#A3ABB3';
    cursor: pointer;
    padding: 12px 0;
    min-width: 77px;
    font-size: 14px;
    text-align: center;
    &.active {
      background-color: #4986c5;
      color: #fff;
    }
  }
}
