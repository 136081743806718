@import '../../../assets/sass/lbd/variables.scss';
@import '../../../assets/sass/lbd/mixins';

.gateway__detail {
  display: flex;
  &.form-row {
    @media only screen and (max-width: 1550px) {
      flex-direction: column;
      justify-content: center;
    }
  }
  .break-all {
    word-break: break-all;
  }
  .commit-msg {
    color: $wedge-blue-gray;
    display: flex;
    flex-wrap: wrap;
    .link {
      color: $wedge-primary-blue;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: $wedge-primary-green;
      }
    }
  }
  .flex-one {
    flex: 1 1;
  }
  &-info {
    word-break: break-all;
  }
  &-vpn {
    @include respond-to($large-desktop) {
      height: fit-content;
    }
    .vpn-collapse {
      margin-left: 24px;
      .field__group {
        margin-bottom: 12px;
      }
    }
    .vpn_title {
      color: $wedge-primary-blue;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 32px;
      width: fit-content;
    }
    .psk-info {
      i {
        cursor: pointer;
      }
      p,
      &__options {
        color: $wedge-light-gray;
        font-size: 14px;
        margin-top: 12px;
      }
      &__options {
        display: flex;
        align-items: center;
        @include respond-to($phone) {
          display: block;
        }
        .psk-data {
          display: flex;
          align-items: center;
          .psk-actions {
            display: flex;
            align-items: center;
          }
          @media only screen and (max-width: 340px) {
            display: block;
            .psk-actions {
              margin-top: 5px;
            }
          }
        }
        .psk-option {
          display: flex;
          align-items: center;
          &::before {
            content: '|';
            padding-left: 8px;
            padding-right: 8px;
          }
        }
        .psk-text {
          font-size: 11px;
          background: none;
          border: 0.5px solid $wedge-light-gray;
          border-radius: 3px;
          padding: 6px;
          margin-left: 6px;
          width: 160px;
          @include respond-to($phone) {
            color: $wedge-primary-black;
          }
        }
        .psk-show {
          display: flex;
          height: 20px;
        }
      }
    }
  }
  &-internet {
    display: flex;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
  .local-networks--container {
    width: 60%;
  }
}

#popover-psk .popover-psk {
  max-width: 280px;
}

#popover-clientkey .popover-clientkey {
  max-width: 360px;
}

#popover-psk,
#popover-clientkey {
  & .popover-psk,
  & .popover-clientkey {
    color: $wedge-blue-gray;
    padding: 8px 16px;
    text-align: center;

    p {
      font-size: 14px;
      margin-bottom: 0;
      &:first-child {
        color: $wedge-light-gray;
      }
    }

    .generate_buttons {
      display: flex;
      justify-content: space-between;
      padding-top: 8px;

      button {
        flex: 0.4;
        font-size: 12px;
      }
    }
  }
}
