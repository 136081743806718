@import '../../assets/sass/lbd/variables.scss';
@import '../../assets/sass/common.scss';

.sidebar.wedge {
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    height: $logo-container-height;

    .full-width {
      flex: 1;
    }

    .divider {
      height: 50px;
      width: 1px;
      background-color: #d0d4d6;
    }
  }
  .sidebar-divider {
    height: 1px;
    margin: 0 32px;
    background-color: #e2eaed;
  }
  .ecosystem--name {
    padding: 32px 32px 24px 32px;
    font-size: 18px;
    color: #a1a1a1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sidebar-badge {
    position: relative;
    margin-left: 20px;
    width: 23px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--container {
      width: 23px;
      height: 16px;
      position: absolute;
    }
    &--value {
      font-size: 12px;
      color: white;
      z-index: 101;
      font-weight: bold;
    }
  }
  .sidebar-item {
    padding: 32px;
    display: flex;
    flex-direction: row;
  }
  .nav-link {
    display: flex;
    align-items: center;
    &.root {
      cursor: pointer;
    }
    &.with-arrow {
      justify-content: space-between;
    }
    .left-container {
      display: flex;
      align-items: center;
    }
    &.ecosystem {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 0;
      padding: 32px;

      p {
        font-size: 20px;
        color: $wedge-brown;
        &.ecosystem--text {
          margin-left: 12px;
        }
      }
    }
    .back-arrow {
      width: 20px;
      height: 15px;
    }
    .small-image {
      &.add {
        margin-left: 8px;
      }
      &.down {
        margin: 0 0 0 8px;
        width: 9px;
        height: 5px;
      }
      &.translations {
        width: 15px;
        height: 21px;
      }
      &.users {
        width: 23px;
        height: 18px;
      }
      &.security {
        width: 18px;
        margin-right: 8px;
      }
      &.policy {
        width: 24px;
        margin-left: 0;
        margin-right: 7px;
      }
      &.profile {
        width: 20px;
        margin-left: 2px;
        margin-right: 10px;
      }
      &.security,
      &.policy,
      &.profile {
        height: auto;
      }
    }
  }
}

h2.page-title {
  margin: 0;
  color: $wedge-blue-gray;
  font-size: 22px;
  font-weight: normal;
  text-transform: capitalize;
}

.navbar-item {
  .dropdown-toggle {
    display: flex;
    flex-direction: row;
    padding: 6px 12px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    border: 0px solid transparent;
    border-radius: 0px;
    background-color: transparent;
    margin: 0;

    .caret {
      display: none;
    }
  }
  .dropdown {
    & .dropdown-toggle {
      align-items: center;
      margin: 0 !important;
      background-color: transparent !important;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important;
      border: 1px solid transparent !important;
    }

    &.open .dropdown-toggle {
      margin: 0 !important;
      background-color: transparent !important;
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.125) !important;
    }
  }
  .dropdown-menu {
    border-radius: 3px;
    margin-top: 5px;
    .wedge-menu-item a {
      padding: 8px 12px !important;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
