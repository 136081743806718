.article_body {
  white-space: pre-wrap;
  border: 1px solid #d1d5d9;
  border-radius: 8px;
  padding: 16px;
  height: auto;
  min-height: 120px;
  height: 200px;
  max-height: unset;
  overflow-y: auto;
  resize: vertical;

  &.zammad {
    white-space: normal;
  }
}
