@import '../../assets/sass/lbd//variables.scss';

.nav-profile.commit-container {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;

  .tooltip {
    width: 320px;
    left: -270px;
    background-color: #b0f8fb;
    border-radius: 5px;
    color: #707070;
    cursor: default;
    display: flex;
    align-items: center;
    font-size: 14px;
    opacity: 1;
    height: 40px;
    z-index: 0;
    &::before {
      content: '\26A0';
      color: #707070;
      font-size: 24px;
      padding: 0 12px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .commit-box {
    background-color: #25c8ea;
    border-radius: 20px;
    height: 40px;
    width: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &.disabled {
      cursor: default;
      background-color: #e1e1e1;
      span {
        margin: 0;
      }
    }

    span {
      color: #fff;
      font-size: 16px;
      line-height: 15px;
      height: 18px;
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}

#popover-commit {
  border: solid 0.5px #dadddf;
  box-shadow: 0 2px 4px 0 #eeeeee;
  z-index: 1291;
  transform: translate(7%, 12px);

  .arrow {
    left: 50% !important;
  }
  .popover-content {
    padding: 0;
  }
  .commit-list {
    padding: 25px 20px 10px 20px;
    max-height: 350px;
    overflow-y: auto;
  }
  .commit-actions {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      border-radius: 14px;
      font-size: 14px;
      height: 28px;
      width: 90px;
      &:focus {
        outline: none;
      }
    }
    .revert-button {
      margin-right: 10px;
      background-color: transparent;
      border: 1px solid #d6d6d6;
      color: #707070;
    }
    .commit-button {
      border: none;
      background-color: $wedge-secondary-blue;
      color: #fff;
    }
  }
}
