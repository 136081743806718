@import '../../assets/sass/lbd/variables.scss';

.fetch-error-message {
  z-index: 1393;
  position: fixed;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  background-color: #f2dede;
  border: 1px solid #a94442;
  color: #a94442;
  padding: 12px 16px;
  border-radius: 2px;
  width: 100%;
  @media only screen and (max-width: 992px) {
    padding: 8px;
  }
  @media only screen and (min-width: 768px) {
    width: 640px;
  }
  &--container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
  &--text {
    font-size: 14px;
    font-weight: 400;
  }
  &--code {
    margin: 0 8px;
    font-size: 15px;
    font-weight: 600;
  }
  &--help,
  .show-detail {
    color: $wedge-primary-blue;
    cursor: pointer;
    font-weight: 600;
    white-space: nowrap;
  }
  & .d-flex {
    display: flex;
    align-items: center;
  }
  & .divider {
    margin: 0 8px;
  }
  p.debug_info {
    font-size: 14px;
    font-weight: 400;
    padding-left: 26px;
  }
}
