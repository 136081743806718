@import '../../assets/sass/lbd/variables.scss';

.form-container-contract {
  max-width: 726px;
  padding: 50px 0 0 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .container {
    width: 100%;
  }
  label {
    display: block;
  }
  .labelForm {
    text-transform: uppercase;
    font-family: 'OpenSans', sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: $wedge-primary-blue;
    letter-spacing: 0;
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
  }
}
