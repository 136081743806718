@import '../../../assets/sass/lbd/variables.scss';
.orgInfo-form {
  display: flex;
  padding: 36px 0;
  justify-content: center;
  flex-direction: column;

  & .error-msg {
    color: red;
    margin-bottom: 15px;
  }

  & .form-container {
    width: 100%;
    position: relative;

    & label {
      width: 100%;
      display: flex;
      flex-direction: column;
      font-weight: 600;
      margin-bottom: 18px;

      & .labelForm {
        margin-bottom: 8px;
        color: $wedge-secondary-blue;
        text-transform: uppercase;
      }

      & .inputField {
        padding: 0 12px;
        height: 48px;
        font-size: 18px;
        font-weight: normal;
        border-radius: 3px;
        border: 1px solid #ccc;
        box-shadow: none;
        &::placeholder {
          color: #ccc;
        }
      }
      & .input-container {
        height: 48px;
        padding: 0px 12px;
      }
    }

    & .title {
      font-weight: 500;
      color: $wedge-primary-gray;
    }

    & .divider {
      width: 100%;
      height: 1px;
      margin-bottom: 24px;
      background-color: $wedge-primary-gray;
    }

    & .password-form .row {
      position: relative;

      & .error-msg {
        left: 15px;
      }
    }
  }

  & .countrySelect {
    button {
      background: #fff;
      padding: 8px 15px !important;
      font-size: 18px;
      font-weight: normal;
    }
    ul {
      overflow-x: hidden;
      div input {
        font-size: 16px;
        font-weight: normal;
      }
      li {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
  .inputFieldDropdownHolder {
    margin: 0;
  }

  .dropdown {
    width: 100%;
    margin: 0;
    text-align: left;

    &.open,
    &.open:active {
      background: #fff;
      box-shadow: none;

      button,
      button:active,
      button:focus,
      button:hover {
        box-shadow: none;
        background: #fff;
      }
    }

    .dropdown-menu {
      width: 100%;
      max-height: 240px;
      overflow-x: hidden;

      li {
        font-family: 'OpenSans', sans-serif;
        font-size: 18px;
        line-height: 20px;

        a {
          color: $wedge-blue-gray;
          white-space: normal;
        }
      }
    }

    .inputFieldDropdown {
      max-width: 100%;
      background: #fff;
      text-align: left;
      width: 100%;
      font-family: 'OpenSans', sans-serif;
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
      color: $wedge-blue-gray;
      box-sizing: border-box;
      padding: 0 15px;
      border: solid 1px #d0d4d6;
      margin: 0;
      border-radius: 2px;
      height: 49px;
      display: block;
      white-space: normal;

      .caret {
        position: absolute;
        top: 50%;
        margin: -3px 0 0 0;
        right: 10px;
      }
    }
  }

  .menu-item_overflow_hidden {
    overflow-x: hidden;
  }
}
