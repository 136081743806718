@import '../../assets/sass/lbd/variables.scss';

.panel__downloads {
  margin-bottom: 16px;
  padding-top: 16px;

  .panel-heading {
    position: relative;

    input {
      border: 1px solid lightgray;
      border-radius: 3px;
      height: 36px;
      padding: 4px 12px;
      width: 100%;
    }
    .search-icon {
      color: $wedge-light-gray;
      position: absolute;
      right: 24px;
      top: 16px;
    }
  }
  .panel-body {
    .download-list {
      &__label {
        color: $wedge-primary-blue;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding-top: 12px;
        i {
          font-size: 32px;
        }
      }
      &__collapse {
        color: $wedge-secondary-black;
        padding-left: 36px;

        .download__option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 0;

          .download__functions {
            display: flex;
            align-items: center;
            i {
              margin: 0 4px;
              cursor: pointer;
            }
            a {
              color: $wedge-secondary-black;
            }
            .material-icons {
              font-size: 18px;
            }
            .config-spinner {
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
}

.popover-content .temporary-popover {
  color: #1dc7ea;
  p {
    color: $wedge-secondary-black;
    font-size: 14px;
  }
}
